import { styled, SxProps } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const SFooterBlockSection = styled("div", {
  shouldForwardProp: (props: string) =>
    props !== "extraMargin" && props !== "noMargin",
})<{
  extraMargin?: boolean;
}>(({ extraMargin, noMargin }) => ({
  display: "flex",
  flexDirection: "column",
  ...(!noMargin && {
    margin: extraMargin ? "0 10rem 0 0" : "0 5rem 0 0",
  }),
}));

const FooterBlockSection = ({ children, sx }: Props) => {
  return <SFooterBlockSection sx={{ ...sx }}>{children}</SFooterBlockSection>;
};

export default FooterBlockSection;
