import { styled, Link, SxProps } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const SFooterBlockLink = styled(Link)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #e4ebf1;
  margin: 0.5rem 0 0.5rem 0;
  width: max-content;
`;

const FooterBlockLink = ({ children, sx }: Props) => {
  return <SFooterBlockLink sx={{ ...sx }}>{children}</SFooterBlockLink>;
};

export default FooterBlockLink;
