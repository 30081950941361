import { IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

type DrawerHeaderProps = {
  callback?: () => void;
};

export const DrawerHeader = ({ callback }: DrawerHeaderProps) =>
  callback ? (
    <Header>
      <IconButton onClick={callback}>
        <ChevronLeftIcon />
      </IconButton>
    </Header>
  ) : (
    <Header />
  );

export default DrawerHeader;
