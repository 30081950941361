import { useEffect, useState } from "react"
import { EditorState, convertFromRaw } from "draft-js"
export interface Data {
  content: Content | null
  selectableContent: {
    title: string | null
    content: ContentArr
  } | null
  selectableTags: string[] | null
  onSelectableTagClick: (tag: string) => void
  onSelectableContentClick: (contentToShow: Content) => void
  goBackToSelectableContent: (data: SelectableContent) => void
  goBackToTags: () => void
}

type Env = "dev" | "test" | "production"

interface Props {
  tags: string[]
  applicationTag: string
  excludedTags: string[]
  excludeVisibleTags: string[]
  env: Env
}

export type SelectableContent = {
  title: string | null
  content: ContentArr
} | null

export type Content = {
  tags: string[]
  content: any
  title: string
}

export type ContentArr = Content[]

const getURL = (env: Env) => {
  switch (env) {
    case "production":
      return "https://help.cloud.studentlitteratur.se/api/content"
    case "test":
      return "https://help.test.studentlitteratur.se/api/content"
    case "dev":
      return "https://help.dev.studentlitteratur.se/api/content"
    default:
      return "https://help.dev.studentlitteratur.se/api/content"
  }
}

function useHelp({
  tags,
  applicationTag,
  env,
  excludedTags,
  excludeVisibleTags
}: Props): Data {
  const [content, setContent] = useState<null | Content[]>(null)
  const [currentContent, setCurrentContent] = useState<null | Content>(null)
  const [selectableTags, setSelectableTags] = useState<null | string[]>(null)
  const [
    selectableContent,
    setSelectableContent
  ] = useState<null | SelectableContent>(null)
  useEffect(() => {
    if (!content && applicationTag) {
      const getContent = async () => {
        const response = await fetchContent(applicationTag)
        const contentWithoutExcluded = removeExcludedTags(response.data)
        setContent(contentWithoutExcluded)
        filterContent(tags, contentWithoutExcluded)
      }
      getContent()
    }
  }, [applicationTag])

  const convertToDraftJs = (contentToConvert: Content) => {
    const parsedContent = JSON.parse(contentToConvert.content)
    const raw = convertFromRaw(parsedContent)
    const editorState = EditorState.createWithContent(raw)
    setCurrentContent({ ...contentToConvert, content: editorState })
  }

  const filterByTags = (filterTags: string[], contentToFilter: ContentArr) => (
    contentToFilter.filter(contentPost =>
      filterTags.some(tag => contentPost.tags.includes(tag))
    )
  )

  const filterContent = (filterTags: string[], contentToFilter: ContentArr) => {
    setCurrentContent(null)
    setSelectableContent(null)
    setSelectableTags(null)
    const uniqueTags = findTags(contentToFilter)
    if (!filterTags.length) {
      setSelectableTags(uniqueTags)
      return
    }

    const pageContent = filterByTags(filterTags, contentToFilter)
    if (pageContent.length === 1) {
      convertToDraftJs(pageContent[0])
      return
    }
    setSelectableContent({
      title: filterTags.length === 1 ? filterTags[0] : null,
      content: pageContent
    })
  }

  const findTags = (contentArr: ContentArr) => {
    const tags: string[] = []
    contentArr.forEach(contentPost => {
      contentPost.tags.forEach(tag => {
        if (tags.indexOf(tag) === -1) {
          tags.push(tag)
        }
      })
    })
    return tags
  }
  const removeExcludedTags = (contentArr: ContentArr) => {
    const bothExcludedAndVisible = [...excludeVisibleTags, ...excludedTags];
    return contentArr
      .filter(contentPost => {
        if (
          !contentPost.tags.some(tag => bothExcludedAndVisible.includes(tag))
        ) {
          return true
        }

        return excludeVisibleTags.some(tag => contentPost.tags.includes(tag))
      })
      .map(contentPost => ({
        ...contentPost,
        tags: contentPost.tags.filter(
          tag =>
            !excludedTags.includes(tag) &&
            !excludeVisibleTags.includes(tag) &&
            tag !== applicationTag
        )
      }))
  }

  const fetchContent = async (applicationTag: string) => {
    try {
      const url = new URL(getURL(env))
      url.searchParams.append("tags", applicationTag)
      const response = await fetch(url.toString(), { method: "GET" })
      return response.json()
    } catch (e) {
      return e
    }
  }

  const onSelectableTagClick = (tag: string) => {
    filterContent([tag], content as ContentArr)
    return
  }

  const onSelectableContentClick = (contentToShow: Content) => {
    convertToDraftJs(contentToShow)
  }

  const goBackToSelectableContent = (data: SelectableContent) => {
    setCurrentContent(null)
    setSelectableContent(data)
  }

  const goBackToTags = () => {
    filterContent([], content as ContentArr)
  }

  return {
    content: currentContent,
    selectableTags,
    selectableContent,
    goBackToSelectableContent,
    goBackToTags,
    onSelectableContentClick,
    onSelectableTagClick
  }
}

export default useHelp
