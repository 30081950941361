import { IconType } from "./index";

const HamburgerOpen = ({ width, height, color }: IconType) => (
  <svg
    fill={color}
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
  >
    <path
      d="M3 24h20M3 16h13M3 8h20M22 16l7 6M22 16l7-6"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default HamburgerOpen;
