import { styled, Typography, SxProps } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const SFooterBlockTitle = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  display: flex;
  align-items: center;
  color: #9aa9b8;
  margin: 0.5rem 0 0.5rem 0;
  width: max-content;
`;

const FooterBlockTitle = ({ children, sx }: Props) => {
  return <SFooterBlockTitle sx={{ ...sx }}>{children}</SFooterBlockTitle>;
};

export default FooterBlockTitle;
