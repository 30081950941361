import { styled } from "@mui/material/styles";
import DrawerHeader from "../DrawerComponent/DrawerHeader";

type ContentProps = {
  children?: React.ReactElement<any, any>;
  open: boolean;
  drawerWidth: number;
  mobile: boolean;
  permanentClosed?: boolean;
};

const Main = styled("div")`
  display: flex;
  flex-direction: column;
`;

//FIX for ios13
const DummyWrapper = styled("div")``;

const MainWrapper = styled("div", {
  shouldForwardProp: (props: string) =>
    props !== "open" &&
    props !== "drawerWidth" &&
    props !== "closed" &&
    props !== "mobile",
})<{
  open?: boolean;
  drawerWidth: number;
  mobile: boolean;
  closed?: boolean;
}>(({ theme, open, drawerWidth, closed, mobile }) => ({
  flexGrow: 1,
  justifyContent: "space-between",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: mobile ? 0 : !open ? 0 : `${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(closed && {
    transition: "none",
  }),
  display: "flex",
  flexDirection: "column",
  maxWidth: open && !mobile ? `calc(100% - ${drawerWidth}px)` : "100%",
}));

const ContentComponent = ({
  children,
  open,
  drawerWidth,
  mobile,
  permanentClosed,
}: ContentProps) => (
  <MainWrapper
    mobile={mobile}
    drawerWidth={drawerWidth}
    open={open}
    closed={permanentClosed}
  >
    <DummyWrapper>
      <Main>
        <DrawerHeader />
        {children}
      </Main>
    </DummyWrapper>
  </MainWrapper>
);

export default ContentComponent;
