import { styled, SxProps } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";

import { HamburgerClosed, HamburgerOpen } from "../../shared/icons";
import { Children } from "react";

type ToolbarProps = {
  open: boolean;
  handleDrawerOpen: () => void;
  handleDrawerClose: () => void;
  title?: string;
  permanentClosed?: boolean;
  children?: React.ReactElement;
  toolbarColor?: string;
  hideToolbarItems?: boolean;
  logo?: React.ReactElement;
  sx?: SxProps;
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (props: string) =>
    props !== "open" && props !== "closed" && props !== "toolbarColor",
})<{ open?: boolean; closed?: boolean; toolbarColor?: string }>(
  ({ theme, open, closed, toolbarColor }) => ({
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      zIndex: 1200,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
    ...(closed && {
      position: "absolute",
    }),
    ...(toolbarColor && {
      backgroundColor: toolbarColor,
    }),
    boxShadow: "none",
  })
);

const ToolbarWrapper = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

const AppbarContentLeftWrapper = styled("div")`
  display: flex;
  align-items: center;
`;

const AppbarContentRightWrapper = styled("div")`
  display: flex;
`;

const ToolbarComponent = ({
  title,
  open,
  permanentClosed,
  children,
  handleDrawerClose,
  handleDrawerOpen,
  toolbarColor,
  sx,
  logo,
}: ToolbarProps) => (
  <AppBar
    toolbarColor={toolbarColor}
    position="fixed"
    open={open}
    sx={sx}
    closed={permanentClosed}
  >
    <ToolbarWrapper>
      <AppbarContentLeftWrapper>
        {!open && !permanentClosed && (
          <IconButton
            color="inherit"
            aria-label="Öppna meny"
            onClick={handleDrawerOpen}
          >
            <HamburgerClosed height="32" width="32" color="currentColor" />
          </IconButton>
        )}
        {open && !permanentClosed && (
          <IconButton
            color="inherit"
            aria-label="Stäng meny"
            onClick={handleDrawerClose}
          >
            <HamburgerOpen height="32" width="32" color="currentColor" />
          </IconButton>
        )}
        {logo && <Box marginLeft="1rem">{logo}</Box>}
        {title && (
          <Typography
            sx={{ marginLeft: "1rem" }}
            variant="h6"
            noWrap
            component="div"
          >
            {title}
          </Typography>
        )}
      </AppbarContentLeftWrapper>

      <AppbarContentRightWrapper>{children}</AppbarContentRightWrapper>
    </ToolbarWrapper>
  </AppBar>
);

export default ToolbarComponent;
