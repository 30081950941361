import Drawer from "@mui/material/Drawer";
import { SxProps, Theme, useMediaQuery } from "@mui/material";
import React from "react";

type DrawerProps = {
  open: boolean;
  drawerWidth: number;
  drawerVariant: "persistent" | "temporary";
  mobile: boolean;
  onClose: () => void;
  sx?: SxProps;
  children?: React.ReactElement;
  drawerHeader?: React.ReactElement;
};

const DrawerComponent = ({
  open,
  drawerWidth,
  drawerVariant,
  mobile,
  onClose,
  sx,
  children,
  drawerHeader,
}: DrawerProps) => {
  const sm = useMediaQuery<Theme>((theme) => theme.breakpoints.up("sm"));
  const [size, setSize] = React.useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => {
      setSize(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <aside>
      <Drawer
        sx={{
          width: sm ? drawerWidth : size - 56,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: sm ? drawerWidth : size - 56,
            boxSizing: "border-box",
            top: mobile ? 0 : "4rem",
            zIndex: mobile ? "1300" : "1000",
            border: "none",
            ...(!mobile && {
              height: "calc(100% - 4rem)",
            }),
          },
          ...sx,
        }}
        variant={drawerVariant}
        anchor="left"
        open={open}
        onClose={onClose}
        ModalProps={{
          keepMounted: mobile, // Better open performance on mobile.
        }}
      >
        {mobile && drawerHeader}
        {children}
      </Drawer>
    </aside>
  );
};

export default DrawerComponent;
