import { styled, SxProps } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

const SFooterBlock = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 0 0 0.5rem 0;
`;

const FooterBlock = ({ children, sx }: Props) => {
  return <SFooterBlock sx={{ ...sx }}>{children}</SFooterBlock>;
};

export default FooterBlock;
