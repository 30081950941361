import { Theme } from "@mui/material";
import useBreakpoints from "./useBreakpoints";

export const getSpacing = (
  theme: Theme,
  closed: boolean,
  noVerticalMargin: boolean
) => {
  const { xl, lg, md, sm, xs } = useBreakpoints();
  const verticalMargin = noVerticalMargin ? 0 : 6;

  if (xl || md) return theme.spacing(verticalMargin, "auto");
  if (lg) return theme.spacing(verticalMargin, closed ? 37.5 : 12.5);
  if (sm) return theme.spacing(verticalMargin, 8);
  if (xs) return theme.spacing(verticalMargin, 4);
};

export const getWidth = () => {
  const { xl, lg, md, sm, xs } = useBreakpoints();

  if (lg || sm || xs) return { width: "auto" };
  if (xl) return { width: "65rem" };
  if (md) return { width: "52.5rem" };
};
