import { styled, Typography, SxProps } from "@mui/material";

type Props = {
  children?: React.ReactNode;
  sx?: SxProps;
};

export const SFooterBlockText = styled(Typography)`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 0.875rem;
  line-height: 1.25rem;
  display: flex;
  align-items: center;
  color: #e4ebf1;
  margin: 0;
  width: max-content;
`;

const FooterBlockText = ({ children, sx }: Props) => {
  return <SFooterBlockText sx={{ ...sx }}>{children}</SFooterBlockText>;
};

export default FooterBlockText;
