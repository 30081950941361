import { useEffect, useRef } from "react";
import useHelp from "./useHelp";
import Draggable from "react-draggable";
import "../../shared/css/help.css";
import HelpContent from "../HelpContent";
import { PositionProps } from "../HelpContent/HelpContent";
import { Dialog, Portal, useMediaQuery } from "@mui/material";

type Props = {
  anchorEl: HTMLButtonElement | null;
  open: boolean;
  applicationTag: string;
  tags: string[];
  excludedTags: string[];
  env: "dev" | "test" | "production";
  closeHelp: () => void;
  excludeVisibleTags?: string[];
  position: PositionProps;
  supportURL: string;
  supportURLFunc?: () => void;
};

const Help = ({
  anchorEl,
  open,
  env,
  tags,
  applicationTag,
  excludedTags,
  excludeVisibleTags = [],
  closeHelp,
  position,
  supportURL,
  supportURLFunc,
}: Props) => {
  const {
    content,
    selectableContent,
    selectableTags,
    onSelectableTagClick,
    onSelectableContentClick,
    goBackToTags,
    goBackToSelectableContent,
  } = useHelp({
    tags,
    applicationTag,
    env,
    excludedTags,
    excludeVisibleTags,
  });
  const isDesktop = useMediaQuery("(min-width:600px)");
  const helpContainer = useRef<HTMLDivElement>();
  const nodeRef = useRef(null);

  useEffect(() => {
    let helpContainerAida = document.getElementById("help-container-aida");
    if (!helpContainerAida) {
      helpContainerAida = document.createElement("div");
      helpContainerAida.id = "help-container-aida";
      document.body.appendChild(helpContainerAida);
    }
    helpContainer.current = helpContainerAida as HTMLDivElement;
  }, []);

  const goToSupport = () => {
    if (supportURLFunc) {
      supportURLFunc();
      return;
    }
    const encodedURI = encodeURIComponent(window.location.href);
    const redirectURL = `${supportURL}/?url=${encodedURI}#supportform`;
    window.open(redirectURL, "_blank", "noopener, noreferrer");
  };

  const onBackClick = (e: any) => {
    e.stopPropagation();

    if (selectableContent && !content) {
      goBackToTags();
    }
    if (content && !selectableContent) {
      goBackToTags();
    }
    if (selectableContent && content) {
      goBackToSelectableContent(selectableContent);
    }
  };

  return (
    <>
      {open && isDesktop && (
        <>
          {helpContainer.current && anchorEl && (
            <Portal container={helpContainer.current}>
              <Draggable
                handle=".handle"
                allowAnyClick={false}
                positionOffset={{
                  x: (anchorEl.getBoundingClientRect().x as unknown) as string,
                  y: (anchorEl.getBoundingClientRect().y as unknown) as string,
                }}
                nodeRef={nodeRef}
              >
                <div style={{ position: "fixed" }} ref={nodeRef}>
                  <HelpContent
                    isDesktop={isDesktop}
                    onBackClick={onBackClick}
                    content={content}
                    selectableTags={selectableTags}
                    selectableContent={selectableContent}
                    onSelectableContentClick={onSelectableContentClick}
                    onSelectableTagClick={onSelectableTagClick}
                    goToSupport={goToSupport}
                    closeHelp={closeHelp}
                    position={position as PositionProps}
                  />
                </div>
              </Draggable>
            </Portal>
          )}
        </>
      )}
      {open && !isDesktop && (
        <Dialog open={open} fullScreen={true}>
          <HelpContent
            isDesktop={isDesktop}
            onBackClick={onBackClick}
            content={content}
            selectableTags={selectableTags}
            selectableContent={selectableContent}
            onSelectableContentClick={onSelectableContentClick}
            onSelectableTagClick={onSelectableTagClick}
            goToSupport={goToSupport}
            closeHelp={closeHelp}
            position={position}
          />
        </Dialog>
      )}
    </>
  );
};

export default Help;
