import { Link, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { getSpacing, getWidth } from "../../shared/helpers";

type FooterProps = {
  open: boolean;
  drawerWidth: number;
  mobile: boolean;
  children?: React.ReactNode;
  logo?: React.ReactElement;
};

const Footer = styled("footer", {
  shouldForwardProp: (props: string) =>
    props !== "open" && props !== "drawerWidth" && props !== "mobile",
})<{
  open?: boolean;
  mobile?: boolean;
  drawerWidth?: number;
}>(({ theme, open, mobile, drawerWidth }) => ({
  justifyContent: "flex-end",
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: mobile ? 0 : !open ? 0 : `${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(closed && {
    transition: "none",
  }),
  display: "flex",
  flexDirection: "column",
  maxWidth: open && !mobile ? `calc(100% - ${drawerWidth}px)` : "100%",
  backgroundColor: "#23272a",
}));

const LogoContainer = styled("div")`
  background-color: #131516;
  height: 65px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const LogoWrapper = styled("div", {
  shouldForwardProp: (props: string) => props !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  backgroundColor: "#131516",
  margin: getSpacing(theme, !open, true),
  ...getWidth(),
}));

const FooterWrapper = styled("div", {
  shouldForwardProp: (props: string) => props !== "open",
})<{
  open?: boolean;
}>(({ theme, open }) => ({
  margin: getSpacing(theme, !open, false),
  ...getWidth(),
}));

const BlockContainer = styled("div")(() => ({
  display: "flex",
  flexWrap: "wrap-reverse",
}));

const FooterComponent = ({
  open,
  children,
  logo,
  mobile,
  drawerWidth,
}: FooterProps) => {
  return (
    <Footer open={open} mobile={mobile} drawerWidth={drawerWidth}>
      <FooterWrapper open={open}>
        <BlockContainer>{children}</BlockContainer>
      </FooterWrapper>
      <LogoContainer>
        <LogoWrapper open={open}>{logo && logo}</LogoWrapper>
      </LogoContainer>
    </Footer>
  );
};

export default FooterComponent;
