import React, { useEffect, useRef } from "react";
import { Portal } from "@mui/material";
import Slider from "./Slider";

const FullscreenSlider = ({ slides, settings, onClose }) => {
  const fullScreenDiv = useRef();

  useEffect(() => {
    let fullscreenContainer = document.getElementById(
      "fullscreen-container-aida"
    );
    if (!fullscreenContainer) {
      fullscreenContainer = document.createElement("div");
      fullscreenContainer.id = "fullscreen-container-aida";
      document.body.appendChild(fullscreenContainer);
    }
    fullScreenDiv.current = fullscreenContainer;
  }, []);

  return (
    <Portal container={fullScreenDiv.current}>
      <div className="fullscreen-slider">
        <button type="button" onClick={onClose} aria-label="Stäng">
          X
        </button>
        <Slider slides={slides} settings={settings} />
      </div>
    </Portal>
  );
};

export default FullscreenSlider;
